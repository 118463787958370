.Content {
    padding: 50px;
    padding-bottom: 50px;
    width: 95%;
    padding-left: 120px;
}

.Carousel {
    padding-bottom: 20px;
}

.contact-us {
    margin: auto;
    background-color: #116196;
    color: #fff;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    column-gap: 100px;
    font-size: 15px;
}

@media screen and (max-width: 900px) {
    .Content {
        padding: 10px;
        padding-top: 80px;
        max-width: 95%;
        padding-left: 80px;
    }
    .contact-us {
        column-gap: 30px;
        font-size: 10px;
    }
}

@media screen and (max-width: 480px) {
    .Content {
        padding: 10px;
        padding-top: 80px;
        max-width: 99%;
    }
}