.service-card {
  display: flex;
  flex-wrap: wrap;
  column-gap: 43px;
  padding-top: 20px;
}

.skill-card {
  flex: 15%;
  padding: 20px;
  width: 240px;
  height: 260px;
  background-color: #0A5485;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .skill-card {
    flex: 40%;
    row-gap: 50px;
    column-gap: 50px;
  }
}

@media screen and (max-width: 420px) {
  .service-card {
    flex-direction: column;
    row-gap: 20px
  }
}

.skill-card:hover .skill-card__header {
  height: 0px;
}

.skill-card:hover .skill-card__body {
  height: 170px;
}

.skill-card .skill-card__header {
  position: relative;
  background-color: #dde3eb;
  border-radius: 1px;
  height: 150px;
  margin: -20px -20px 20px -20px;
  transition: height .5s;
  overflow: hidden;
}

.skill-card .skill-card__title {
  color: #fff;
  padding-bottom: 10px;
  font-size: 16px;
}

.skill-card .skill-card__header .skill-card__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  width: 240px;
  height: 150px;
  transition: transform .5s;
}

.skill-card .skill-card__body {
  height: 80px;
  transition: height .5s;
  overflow: hidden;
  color: #fff;
  font-size: 13px;
}

.skill-card:hover .skill-card__body {
  height: 240px;
}

.skill-card .skill-card__body button {
  padding: 5px 10px;
  text-decoration: none;
}