.team {
    padding-top: 10px;
}

.team .team-images {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 100px;
}

.team .images {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}

.team-image-size {
    height: 380px;
    width: 480px;
}

.team-individual-image-size {
    height: 320px;
    width: 320px;
}

@media screen and (max-width: 560px) {
    .team .team-images {
        flex-direction: column;
    }
    .team-image-size {
        height: 320px;
        width: 320px;
    }
    .team .images {
        flex-direction: column;
    }
}
@media screen and (max-width: 420px) {
    .team .team-images {
        flex-direction: column;
    }
    .team-image-size {
        height: 320px;
        width: 320px;
        background-color: red;
    }
    .team-individual-image-size {
        height: 320px;
        width: 320px;
    }
    .team .images {
        flex-direction: column;
    }
}