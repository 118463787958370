.Footer {
    text-align: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    font-family: "Josefin Sans", sans-serif;
    color: #fff;
    background-color: #116196;
    font-size: 15px;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 300px;
}