.Header {
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 999;
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
  background-color: #116196;
  font-size: 20px;
  padding: 16px;
}

.Header a {
  color: #fff;
  text-decoration: none;
}

.HeaderList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.HeaderListItem {
  margin-right: 20px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
}

.HeaderListItem a {
  color: #fff;
  text-decoration: none;
}

.navbar-item-spacer-phone {
  flex: 68 2 auto;
}

.navbar-item-spacer {
  flex: 12 2 auto;
}

@media (min-width:768px) and (max-width:1080px) {
  .Header {
    flex-direction: column;
    margin: 0;
  }

}

@media (max-width:760px) {
  .Header {
    flex-direction: column;
    margin: 0;
    font-size: 15px;
  }

  .HeaderListItem {
    font-size: 12px;
  }
}

@media (max-width:420px) {
  .Header {
    flex-direction: column;
    margin: 0;
    font-size: 12px;
    padding: 8px;
  }

  .HeaderListItem {
    font-size: 10px;
  }
}